import React from 'react';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import SlideShow from '../components/SlideShow';

var settings = {
  images: [
    { url: require('../assets/images/bg03.webp'), position: 'center' },
    { url: require('../assets/images/bg01.webp'), position: 'center' },
    { url: require('../assets/images/bg02.webp'), position: 'center' },
  ],
  // Delay.
  delay: 6001,
};

const ThankYouPage = () => (
  <Layout>
    <h1>Contact</h1>
    <p>Thank you for your submission!</p>
    <Footer />
    <SlideShow settings={settings} />
  </Layout>
);

export default ThankYouPage;
